import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import {
  createSupplierJob,
  getSupplierJobs,
  updateSupplierJob,
  getSupplierJob,
  recursiveGetSupplierJobs,
} from '../services/supplierJobsService';
import { SupplierJobsInitialState } from '../globalSlices/supplierJobsSlice';

export const createSupplierJobsExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'createSupplierJob';
  builder.addCase(createSupplierJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createSupplierJob.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createSupplierJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getSupplierJobsExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'getSupplierJobs';
  builder.addCase(getSupplierJobs.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
  });
  builder.addCase(
    getSupplierJobs.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(getSupplierJobs.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getSupplierJobs: action.payload }]),
      ];
    }
  });
};

export const updateSupplierJobExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'updateSupplierJob';
  builder.addCase(updateSupplierJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    updateSupplierJob.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(updateSupplierJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getSupplierJobExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'getSupplierJob';
  builder.addCase(getSupplierJob.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getSupplierJob.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload.data) {
        state.selectedSupplierJob = action.payload.data;
      }
    }
  );
  builder.addCase(getSupplierJob.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const recursiveGetSupplierJobsExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierJobsInitialState>
) => {
  const reducerId = 'recursiveGetSupplierJobs';
  builder.addCase(recursiveGetSupplierJobs.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
  });
  builder.addCase(
    recursiveGetSupplierJobs.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(recursiveGetSupplierJobs.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getSupplierJobs: action.payload }]),
      ];
    }
  });
};
