import React, { useEffect, useMemo, useState } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { Bank, CaretLeft, CreditCard } from 'phosphor-react';
import { AppRadioButton } from '../AppRadioButton/AppRadioButton';
import { usePlaidLink } from 'react-plaid-link';
import { myFirebaseAuth } from '../../../firebase/config';
import type { PlaidLinkOnSuccess } from 'react-plaid-link';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { endProcessProgress, startProcessProgress } from '../../../redux/globalSlices/authSlice';
import axios from 'axios';
import {
  createSupplierPlaidPaymentMethod,
  getSupplierPaymentMethods,
  createPlaidPaymentMethod,
  getMainBuyerPaymentMethods,
  createStripeExternalBankToSupplierAccount,
  getStripeSupplierAccount,
  createStripePaymentMethod,
} from '../../../redux/services/paymentsService';
import { Modal } from '@mui/material';
import './AppAddPaymentMethodModal.scss';
import { getPlaidLinkToken } from '../../../redux/services/authService';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { BASE_API_URL } from '../../../utils/constants';
import {
  Buyer,
  calculateStripePartialFacilitationFeesAndAmount,
  capitalizeWordsInSentence,
  Supplier,
} from '@dill/dill-shared';
import AppAddDwollaBankFundingSourceModal from './AppAddDwollaBankFundingSourceModal/AppAddDwollaBankFundingSourceModal';
import { useParamMainBuyerId } from '../../../utils/hooks/useParamMainBuyerId';

const AppAddPaymentMethodModal = ({
  open,
  title,
  handleClose = () => {},
  userType = 'supplier',
  paymentOptionsToShow = 'ALL',
}: {
  title?: string;
  open: boolean;
  handleClose: () => void;
  userType?: 'supplier' | 'buyer';
  paymentOptionsToShow?: 'ALL' | 'CARD' | 'BANK';
}) => {
  const dispatch = useAppDispatch();
  const { selectedMainBuyer, selectedBuyerSuppliers } = useAppSelector((state) => state.buyers);
  const stripe: any = useStripe();
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const elements: any = useElements();
  const { plaidTokenDetails, user } = useAppSelector((state) => state.auth);
  const [paymentType, setPaymentType] = useState('bankTransfer');
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [plaidPublicToken, setPlaidPublicToken] = useState('');
  const [stripeBankToken, setStripeBankToken] = useState('');
  const [addingPayments, setAddingPayments] = useState(false);
  const [paymentMethodCreateErrorMessage, setPaymentMethodCreateErrorMessage] = useState('');
  const [selectedPlaidAccountDetails, setSelectedPlaidAccountDetails] = useState<{
    accountName: string;
    institutionName: string;
    mask: string;
  } | null>(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [isAddDwollaBankFundingSourceModalOpen, setIsAddDwollaBankFundingSourceModalOpen] =
    useState(false);

  useEffect(() => {
    setSelectedPlaidAccountDetails(null);
    dispatch(getPlaidLinkToken());
    setPaymentMethodCreateErrorMessage('');
    setAddingPayments(false);

    return () => {};
  }, []);
  useEffect(() => {
    if (open) {
      setPlaidPublicToken('');
      setStripeBankToken('');
      setPaymentMethodCreateErrorMessage('');
      setSelectedPlaidAccountDetails(null);
    }
    return () => {};
  }, [open]);

  useEffect(() => {
    if (plaidTokenDetails && plaidTokenDetails.link_token) {
      setLinkToken(plaidTokenDetails.link_token);
    }
    return () => {};
  }, [plaidTokenDetails]);

  const handlePlaidLinkSuccess: PlaidLinkOnSuccess = async (publicToken, metadata) => {
    setPaymentMethodCreateErrorMessage('');
    setSelectedPlaidAccountDetails({
      accountName: metadata.accounts[0].name,
      institutionName: metadata.institution?.name || '',
      mask: metadata.accounts[0].mask,
    });
    // Exchange Plaid's Public Token for a Processor Token
    const token = await myFirebaseAuth.currentUser?.getIdToken();
    if (!token) {
      return null;
    }
    dispatch(startProcessProgress('getPlaidPublicToken'));
    const processorTokenResponse = await axios.post(
      `${BASE_API_URL}/paymentsAPI/plaid/publicToken`,
      { accountId: metadata.accounts[0].id, publicToken },
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    dispatch(endProcessProgress('getPlaidPublicToken'));
    if (processorTokenResponse.data && processorTokenResponse.data.success) {
      setPlaidPublicToken(processorTokenResponse.data?.data?.processorToken);
      setStripeBankToken(processorTokenResponse.data?.data?.stripeBankToken || '');
    }
  };
  const { open: openPlaidLink, ready: isPlaidLinkReady } = usePlaidLink({
    onSuccess: handlePlaidLinkSuccess,
    token: linkToken,
  });

  const paymentOptions = useMemo(() => {
    let options: { id: string; name: string }[] = [];
    if (paymentOptionsToShow === 'ALL') {
      options = [
        { id: 'bankTransfer', name: 'Bank Transfer' },
        { id: 'creditCard', name: 'Credit / Debit Card' },
      ];
    } else if (paymentOptionsToShow === 'BANK') {
      options = [{ id: 'bankTransfer', name: 'Bank Transfer' }];
    } else if (paymentOptionsToShow === 'CARD') {
      options = [{ id: 'creditCard', name: 'Credit / Debit Card' }];
    }

    return options;
  }, []);
  const handlePlaid = () => {
    setPaymentMethodCreateErrorMessage('');
    openPlaidLink();
  };

  const handleAddPaymentMethod = async () => {
    setAddingPayments(true);
    if (!selectedPlaidAccountDetails?.accountName) {
      setPaymentMethodCreateErrorMessage('Failed to get bank name');
      setAddingPayments(false);
      return;
    }
    if (userType === 'supplier') {
      if (stripeBankToken) {
        await dispatch(
          createStripeExternalBankToSupplierAccount({ bankOrCardToken: stripeBankToken })
        );
        setStripeBankToken('');
        await dispatch(getStripeSupplierAccount());
      }
      const results = await dispatch(
        createSupplierPlaidPaymentMethod({
          fundingSourceName: `${selectedPlaidAccountDetails.institutionName} ****${selectedPlaidAccountDetails.mask}`,
          plaidToken: plaidPublicToken,
        })
      );
      if (
        results.type === 'payments/createSupplierPlaidPaymentMethod/rejected' &&
        results.payload.errors
      ) {
        const errorMessageSplit = results.payload.errors[0].msg.split('id=');
        const errorMessage = errorMessageSplit[0];
        setPaymentMethodCreateErrorMessage(errorMessage);
      } else {
        setPlaidPublicToken('');
        setStripeBankToken('');
        await dispatch(getSupplierPaymentMethods());
        handleClose();
      }
    } else {
      if (stripeBankToken) {
        await dispatch(
          createStripeExternalBankToSupplierAccount({ bankOrCardToken: stripeBankToken })
        );
        setStripeBankToken('');
      }
      const buyerResults = await dispatch(
        createPlaidPaymentMethod({
          fundingSourceName: `${selectedPlaidAccountDetails.institutionName} ****${selectedPlaidAccountDetails.mask}`,
          plaidToken: plaidPublicToken,
        })
      );
      if (
        buyerResults.type === 'payments/createPlaidPaymentMethod/rejected' &&
        buyerResults.payload.errors
      ) {
        const errorMessageSplit = buyerResults.payload.errors[0].msg.split('id=');
        const errorMessage = errorMessageSplit[0];
        setPaymentMethodCreateErrorMessage(errorMessage);
      } else {
        setPlaidPublicToken('');
        setStripeBankToken('');
        await dispatch(getMainBuyerPaymentMethods({ mainBuyerId: selectedMainBuyerId || '' }));
        handleClose();
      }
    }
    setAddingPayments(false);
  };
  const handleCreateCreditCard = async (event: any) => {
    event.preventDefault();
    setPaymentMethodCreateErrorMessage('');
    if (!stripe || !elements) {
      return;
    }
    setAddingPayments(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (error) {
      console.error(error);
      setPaymentMethodCreateErrorMessage(error.message);
    } else {
      const response = await dispatch(
        createStripePaymentMethod({
          mainBuyerId: selectedMainBuyerId || '',
          paymentMethodId: paymentMethod.id,
          billingName: `${capitalizeWordsInSentence(paymentMethod.card.brand)} ****${
            paymentMethod?.card?.last4
          }`,
        })
      );

      if (response.type === 'payments/createStripePaymentMethod/fulfilled') {
        await dispatch(getMainBuyerPaymentMethods({ mainBuyerId: selectedMainBuyerId || '' }));
        handleClose();
      } else {
        if (response?.payload) {
          const message = response?.payload as string;
          setPaymentMethodCreateErrorMessage(message);
        }
      }
    }
    setAddingPayments(false);
  };
  const handleCardChange = (event: any) => {
    setCardComplete(event.complete);
  };

  const creditCardTakeRate = useMemo(() => {
    if (userType === 'supplier') {
      return 0;
    }
    const buyer = user?.userBuyers?.find(
      (buy) => selectedMainBuyer && selectedMainBuyer.buyerId === buy.id
    );
    let calculateAmount = {} as {
      supplierFees: number;
      buyerFees: number;
      amount: number;
      amountToPay: number;
      totalFees: number;
    };
    let calculationDetails = { amount: 100 } as {
      amount: number;
      buyer?: Buyer;
      supplier?: Supplier;
    };
    if (buyer) {
      calculationDetails = {
        ...calculationDetails,
        buyer: { ...buyer, creditCardTakeRateExtra: 0 },
      };
      const supplier = selectedBuyerSuppliers?.find((sup) => buyer?.supplierId === sup.id);
      if (supplier) {
        calculationDetails = {
          ...calculationDetails,
          supplier: { ...supplier, creditCardTakeRateExtra: 0 },
        };
      }
    }
    calculateAmount = calculateStripePartialFacilitationFeesAndAmount({
      ...calculationDetails,
    });
    return calculateAmount?.buyerFees ?? 0;
  }, [open, selectedMainBuyer, selectedBuyerSuppliers]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-80 w-[40vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
        <AppAddDwollaBankFundingSourceModal
          userType={userType}
          open={isAddDwollaBankFundingSourceModalOpen}
          handleClose={(isSuccessfullyAdded) => {
            setIsAddDwollaBankFundingSourceModalOpen(false);
            if (isSuccessfullyAdded) {
              handleClose();
            }
          }}
        />
        <div className="flex items-center mt-2">
          <AppButton
            type="FLAT"
            buttonStyles={{
              width: '40px',
              height: '40px',
              marginLeft: '12px',
              marginRight: '12px',
            }}
            icon={<CaretLeft size={24} color={'#000000'} />}
            onClick={handleClose}
            isDisabled={addingPayments}
          />

          <h3 className="font-bold text-xl">Add New Payment Method</h3>
        </div>

        <hr className="h-px my-1 bg-gray-200 border-0 dark:bg-gray-200"></hr>

        <div className="flex flex-col px-4 mt-4 grow overflow-y-scroll">
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
              <p className="text-sm font-bold ml-4 mb-2">Select a Payment Method</p>
            </div>

            {paymentOptions.map((option, i) => {
              return (
                <div key={i + 'oo'} className="w-full px-4 my-1">
                  <button
                    className={
                      'flex flex-row w-full rounded-xl WHITE-BG px-5 py-4 border  items-center cursor-pointer ' +
                      (option.id === paymentType ? 'PRIMARY_500-BORDER' : ' GREY_300-BORDER')
                    }
                    onClick={() => {
                      setPaymentType(option.id);
                    }}>
                    <div className="flex mr-2 items-center">
                      <AppRadioButton isChecked={option.id === paymentType} />
                      <div className="payment-type-logo">
                        {option.name === 'Bank Transfer' ? (
                          <Bank size={30} style={{ display: 'flex', alignSelf: 'center' }} />
                        ) : (
                          <CreditCard size={30} style={{ display: 'flex', alignSelf: 'center' }} />
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between w-full items-center">
                      <div className="flex flex-col">
                        <p className="text-sm font-bold text-left">{option.name}</p>
                      </div>
                      {option.id === 'creditCard' &&
                      creditCardTakeRate &&
                      creditCardTakeRate > 0 ? (
                        <div className="flex-end">
                          <div className="text-sm TEXT_SECONDARY-CLR ">Fees</div>
                          <div className="text-xs PRIMARY_500-CLR">{`~${creditCardTakeRate}%`}</div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </button>
                </div>
              );
            })}

            {paymentType === 'bankTransfer' && (
              <div className="flex flex-col p-4 PRIMARY_50-BG mt-2">
                {isPlaidLinkReady && (
                  <div className="my-1">
                    <AppButton
                      buttonStyles={{ width: '100%' }}
                      text="I have access to the online bank portal"
                      onClick={() => {
                        handlePlaid();
                      }}
                    />
                  </div>
                )}
                <div className="my-1">
                  <AppButton
                    buttonStyles={{ width: '100%' }}
                    text="I don’t have access"
                    onClick={() => {
                      setIsAddDwollaBankFundingSourceModalOpen(true);
                    }}
                  />
                </div>
                {selectedPlaidAccountDetails && (
                  <div className="mt-3 ">
                    <p className="text-sm mb-1 font-bold">Selected bank details</p>
                    <p className="text-xs mb-1 ">
                      {`${selectedPlaidAccountDetails.accountName} from ${selectedPlaidAccountDetails.institutionName}`}
                    </p>
                  </div>
                )}
              </div>
            )}
            {paymentType === 'creditCard' && (
              <div className="flex flex-col p-4 PRIMARY_50-BG mt-2">
                <form onSubmit={handleCreateCreditCard}>
                  <label className="text-xs mb-1" htmlFor="card-element">
                    {/* Card details: */}
                    <CardElement id="card-element" onChange={handleCardChange} />
                  </label>
                </form>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col w-full  mb-3">
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
          {paymentMethodCreateErrorMessage && (
            <p className="text-xs mb-3 mx-3 text-center  ERROR_500-CLR">
              {paymentMethodCreateErrorMessage}
            </p>
          )}
          <div className="flex flex-row self-end ">
            <AppButton
              text={'Close'}
              type="TERTIARY"
              buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
              onClick={() => {
                handleClose();
              }}
              isDisabled={addingPayments}
            />

            {paymentType === 'creditCard' ? (
              <AppButton
                text="Create"
                buttonStyles={{ marginLeft: '10px', marginRight: '10px', height: '37px' }}
                onClick={handleCreateCreditCard}
                // isDisabled={formIsValid}
                isLoading={addingPayments}
              />
            ) : (
              <AppButton
                text="Add"
                buttonStyles={{ marginLeft: '10px', marginRight: '10px', height: '37px' }}
                onClick={handleAddPaymentMethod}
                isDisabled={!plaidPublicToken}
                isLoading={addingPayments}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppAddPaymentMethodModal;
