import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { myFirebaseAuth } from '../../firebase/config';
import { LienApiPostBody } from '@dill/dill-shared';
import { firebaseFileUploadHelper } from '../../utils/helpers';
import { RootState } from '../store';

export const createExternalPaymentMethod = createAsyncThunk(
  'payments/createExternalPaymentMethod',
  async (
    {
      accountType,
      routingNumber,
      bankAccountNumber,
      userType,
    }: {
      accountType: string;
      routingNumber: string;
      bankAccountNumber: string;
      userType: 'supplier' | 'buyer';
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<any>(
        `${BASE_API_URL}/paymentsAPI/externalBankAccount`,
        {
          accountType,
          routingNumber,
          bankAccountNumber,
          userType,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return { success: true, data: response.data };
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      let errorMessage = '';
      if (error?.response?.data?.errors) {
        errorMessage = [...error?.response?.data?.errors].reduce((prev, curr) => {
          if (curr.msg) {
            return `${prev} ${curr.msg}`;
          }
          return prev;
        }, '');
      } else {
        errorMessage = error?.message;
      }
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const verifyDwollaMicroTransactions = createAsyncThunk(
  'payments/verifyDwollaMicroTransactions',
  async (
    {
      paymentMethodId,
      amount1,
      amount2,
    }: {
      paymentMethodId: string;
      amount1: number;
      amount2: number;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<any>(
        `${BASE_API_URL}/generics/dwollaGenerics/verifyDwollaMicroTransactions`,
        {
          paymentMethodId,
          amount1,
          amount2,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return { success: true, data: response.data };
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      let errorMessage = '';
      if (error?.response?.data?.errors) {
        errorMessage = [...error?.response?.data?.errors].reduce((prev, curr) => {
          if (curr.msg) {
            return `${prev} ${curr.msg}`;
          }
          return prev;
        }, '');
      } else {
        errorMessage = error?.message;
      }
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const createPlaidPaymentMethod = createAsyncThunk(
  'payments/createPlaidPaymentMethod',
  async (
    {
      fundingSourceName,
      plaidToken,
    }: {
      fundingSourceName: string;
      plaidToken: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<any>(
        `${BASE_API_URL}/paymentsAPI/plaidFundSource`,
        {
          fundingSourceName,
          plaidToken,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const createSupplierPlaidPaymentMethod = createAsyncThunk(
  'payments/createSupplierPlaidPaymentMethod',
  async (
    {
      fundingSourceName,
      plaidToken,
    }: {
      fundingSourceName: string;
      plaidToken: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<any>(
        `${BASE_API_URL}/suppliers/plaid/createSupplierPlaidFundSource`,
        {
          fundingSourceName,
          plaidToken,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const createSupplierPaymentMethod = createAsyncThunk(
  'payments/supplierBankAccount',
  async (
    {
      supplierId,
      legalBankAccountName,
    }: {
      supplierId: string;
      legalBankAccountName: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<any>(
        `${BASE_API_URL}/paymentsAPI/supplierBankAccount`,
        {
          supplierId,
          legalBankAccountName,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return { success: true, data: response.data };
    } catch (error: any) {
      console.log(error?.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getMainBuyerPaymentMethods = createAsyncThunk(
  'payments/getMainBuyerPaymentMethods',
  async ({ mainBuyerId }: { mainBuyerId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/payments/getPaymentMethods`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSupplierPaymentMethods = createAsyncThunk(
  'payments/getSupplierPaymentMethods',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/payments/supplierPaymentMethods`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const supplierWithdraw = createAsyncThunk(
  'payments/supplierWithdraw',
  async (
    { supplierPaymentMethodId, amount }: { supplierPaymentMethodId: string; amount: number },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<any>(
        `${BASE_API_URL}/suppliers/payments/supplierWithdraw`,
        {
          supplierPaymentMethodId,
          amount,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getBuyerTransactions = createAsyncThunk(
  'payments/getBuyerTransactions',
  async (
    {
      mainBuyerId,
      startDate,
      endDate,
      timezone,
    }: { mainBuyerId: string; startDate: string; endDate: string; timezone: string },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/payments/transactions?startDate=${startDate}&endDate=${endDate}&timezone=${timezone}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      const state = thunkApi.getState() as RootState;
      if (
        response?.data?.serverRequestId &&
        state?.buyers?.selectedMainBuyer &&
        response?.data?.serverRequestId === state?.buyers?.selectedMainBuyer.id
      ) {
        return response.data;
      } else {
        console.log('========');
        return null;
      }
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getBuyerTransactionsFiles = createAsyncThunk(
  'payments/getBuyerTransactionsFiles',
  async (
    {
      mainBuyerId,
      startDate,
      endDate,
    }: { mainBuyerId: string; startDate: string; endDate: string },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<any>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/payments/getTransactionsFiles?startDate=${startDate}&endDate=${endDate}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'payment files.zip');
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getTransactionById = createAsyncThunk(
  'payments/getTransactionById',
  async ({ transactionId }: { transactionId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/paymentsAPI/transactions/${transactionId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const payInvoices = createAsyncThunk(
  'payments/pay',
  async (
    {
      mainBuyerId,
      invoiceData,
      sendRelease,
      releaseData,
    }: {
      mainBuyerId: string;
      invoiceData: {
        senderDillPaymentMethodId: string;
        receivingSupplierId: string;
        buyerId: string;
        invoices: any;
        partialPaymentReason?: string | null;
        recordType?: 'FINANCE_CHARGE' | 'DEBIT_MEMO' | 'INVOICE';
        partialPaymentNotes?: string;
        partialPaymentAttachmentsFiles?: File[];
      };
      sendRelease: boolean;
      releaseData: LienApiPostBody[];
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const finalInvoiceData = invoiceData;
      const partialPaymentAttachments: { url: string; name: string }[] = [];
      if (
        invoiceData?.partialPaymentAttachmentsFiles &&
        invoiceData?.partialPaymentAttachmentsFiles.length > 0
      ) {
        for (const partialPaymentAttachmentsFile of invoiceData.partialPaymentAttachmentsFiles) {
          const blobUrl = URL.createObjectURL(partialPaymentAttachmentsFile);
          const downloadUrl = await firebaseFileUploadHelper({
            blobUrl: blobUrl,
            fileNameWithExtension: `${new Date().getTime()}-${partialPaymentAttachmentsFile.name}`,
            storagePath: `transactions/${invoiceData.receivingSupplierId}/${invoiceData.buyerId}`,
          });
          if (downloadUrl) {
            partialPaymentAttachments.push({
              url: downloadUrl,
              name: partialPaymentAttachmentsFile.name,
            });
          }
        }

        delete finalInvoiceData.partialPaymentAttachmentsFiles;
      }

      const releases = [];
      for (let index = 0; index < releaseData.length; index++) {
        const release = releaseData[index];
        const downloadUrl = await firebaseFileUploadHelper({
          blobUrl: release.previewPdfUrl,
          fileNameWithExtension: `${new Date().getTime()}-unsigned.pdf`,
          storagePath: release?.notes ?? '',
        });
        releases.push({ ...release, previewPdfUrl: downloadUrl ?? '', notes: '' });
      }
      const response = await axios.post<any>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/payments/pay`,
        {
          invoiceData: { ...invoiceData, partialPaymentAttachments },
          sendRelease,
          releaseData: releases,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return { success: true, data: response.data };
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const dwollaTransactionLimitNotifyDill = createAsyncThunk(
  'payments/dwollaTransactionLimitNotifyDill',
  async (
    {
      supplierId,
      buyerId,
    }: {
      supplierId: string;
      buyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.post<any>(
        `${BASE_API_URL}/paymentsAPI/dwollaTransactionLimitNotifyDill`,
        {
          supplierId,
          buyerId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return { success: true, data: response.data };
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const supplierPayWillCallInvoices = createAsyncThunk(
  'payments/supplierPayWillCallInvoices',
  async (
    {
      invoiceData,
      whoPaysFee,
    }: {
      whoPaysFee: 'SUPPLIER' | 'BUYER';
      invoiceData: {
        senderStripePaymentMethodId: string;
        receivingSupplierId: string;
        buyerId: string;
        invoices: any;
        partialPaymentReason?: string | null;
      };
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.post<any>(
        `${BASE_API_URL}/suppliers/payments/supplierPayWillCallInvoices`,
        {
          whoPaysFee,
          invoiceData,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return { success: true, data: response.data };
    } catch (error: any) {
      console.log(error);
      let errorMessage = '';
      if (error?.response?.data?.errors) {
        errorMessage = [...error?.response?.data?.errors].reduce((prev, curr) => {
          if (curr.msg) {
            return `${prev} ${curr.msg}`;
          }
          return prev;
        }, '');
      } else {
        errorMessage = error?.message;
      }
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
export const getSupplierTransactions = createAsyncThunk(
  'payments/getSupplierTransactions',
  async (
    { startDate, endDate, timezone }: { startDate: string; endDate: string; timezone: string },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/payments/supplierPayments?startDate=${startDate}&endDate=${endDate}&timezone=${timezone}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getSupplierPaymentsFiles = createAsyncThunk(
  'payments/getSupplierPaymentsFiles',
  async ({ startDate, endDate }: { startDate: string; endDate: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<any>(
        `${BASE_API_URL}/suppliers/payments/getSupplierPaymentsFiles?startDate=${startDate}&endDate=${endDate}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'payment files.zip');
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const generateDwollaToken = createAsyncThunk(
  'payments/generateDwollaToken',
  async (
    {
      req,
      body,
    }: {
      req: any;
      body: any;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const dataReq: any = {
        action: req.action,
      };
      if (req.links) {
        dataReq._links = req.links;
      }
      const response = await axios.post<any>(
        `${BASE_API_URL}/paymentsAPI/tokenUrl`,
        {
          dataReq,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return { success: true, data: response.data };
    } catch (error: any) {
      console.log(error?.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getDwollaCustomer = createAsyncThunk(
  'payments/getDwollaCustomer',
  async (
    {
      email,
      id,
    }: {
      email?: string;
      id?: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const params = {
        email,
        id,
      };
      const response = await axios.get<any>(`${BASE_API_URL}/paymentsAPI/getDwollaCustomer`, {
        params,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      if (response.data.success) {
        return { success: true, data: response.data };
      } else {
        return { success: false, data: {} };
      }
    } catch (error: any) {
      console.log(error?.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getBeneficialOwnershipStatus = createAsyncThunk(
  'payments/getBeneficialOwnershipStatus',
  async (
    {
      id,
    }: {
      id: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const params = {
        id,
      };
      const response = await axios.get<any>(
        `${BASE_API_URL}/paymentsAPI/getBeneficialOwnershipStatus`,
        {
          params,
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response.data.success) {
        return { success: true, data: response.data };
      } else {
        return { success: false, data: {} };
      }
    } catch (error: any) {
      console.log(error?.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getWeekPaymentTransactionsLimitationsForMainBuyer = createAsyncThunk(
  'payments/getWeekPaymentTransactionsLimitationsForMainBuyer',
  async ({ mainBuyerId }: { mainBuyerId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<any>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/payments/weekPaymentTransactionsLimitations`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response.data.success) {
        return { success: true, data: response.data.data };
      } else {
        return { success: false, data: {} };
      }
    } catch (error: any) {
      console.log(error?.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getStripeConfig = createAsyncThunk(
  'payments/getStripeConfig',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<any>(`${BASE_API_URL}/generics/stripeGenerics/getConfig`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      if (response.data.success) {
        return { success: true, data: response.data.data };
      } else {
        return { success: false, data: {} };
      }
    } catch (error: any) {
      console.log(error?.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const createStripePaymentMethod = createAsyncThunk(
  'payments/createStripePaymentMethod',
  async (
    {
      paymentMethodId,
      billingName,
      mainBuyerId,
    }: { mainBuyerId: string; billingName: string; paymentMethodId: string },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<any>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/payments/addStripePaymentMethod`,
        {
          paymentMethodId,
          billingName,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response.data.success) {
        return { success: true, data: response.data.data };
      } else {
        return { success: false, data: {} };
      }
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getStripeSupplierAccount = createAsyncThunk(
  'payments/getStripeSupplierAccount',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<any>(`${BASE_API_URL}/paymentsAPI/stripe/supplierAccount`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      if (response.data.success) {
        return { success: true, data: response.data.data };
      } else {
        return { success: false, data: {} };
      }
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const createStripeExternalBankToSupplierAccount = createAsyncThunk(
  'payments/createStripeExternalBankToSupplierAccount',
  async ({ bankOrCardToken }: { bankOrCardToken: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<any>(
        `${BASE_API_URL}/paymentsAPI/stripe/supplierAccount/externalBank`,
        { bankOrCardToken },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response.data.success) {
        return { success: true, data: response.data.data };
      } else {
        return { success: false, data: {} };
      }
    } catch (error: any) {
      console.log(error?.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getSupplierRegistrationStatus = createAsyncThunk(
  'payments/getSupplierRegistrationStatus',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<any>(
        `${BASE_API_URL}/suppliers/payments/supplierRegistrationStatus`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response.data.success) {
        return { success: true, data: response.data.data };
      } else {
        return { success: false, data: {} };
      }
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateTransaction = createAsyncThunk(
  'payments/updateTransaction',
  async (
    {
      mainBuyerId,
      transactionId,
      transactionDetails,
    }: {
      mainBuyerId: string;
      transactionId: string;
      transactionDetails: any;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<AppServerResponse>(
        BASE_API_URL + `/mainBuyers/${mainBuyerId}/transactions/${transactionId}`,
        { ...transactionDetails },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const deletePaymentMethodByMainBuyer = createAsyncThunk(
  'payments/deletePaymentMethod',
  async (
    {
      mainBuyerId,
      paymentMethodId,
    }: {
      mainBuyerId: string;
      paymentMethodId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.delete<AppServerResponse>(
        BASE_API_URL + `/mainBuyers/${mainBuyerId}/payments/deletePaymentMethod/${paymentMethodId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      let errorMessage = '';
      if (error?.response?.data?.errors) {
        errorMessage = [...error?.response?.data?.errors].reduce((prev, curr) => {
          if (curr.msg) {
            return `${prev} ${curr.msg}`;
          }
          return prev;
        }, '');
      } else {
        errorMessage = error?.message;
      }
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const sendInvoicePaymentReminderEmail = createAsyncThunk(
  'payments/sendInvoicePaymentReminderEmail',
  async (
    {
      buyerId,
      invoiceId,
    }: {
      buyerId: string;
      invoiceId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const params = {
        buyerId,
        invoiceId,
      };
      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/payments/sendInvoicePaymentReminderEmail`,
        {
          params,
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      let errorMessage = '';
      if (error?.response?.data?.errors) {
        errorMessage = [...error?.response?.data?.errors].reduce((prev, curr) => {
          if (curr.msg) {
            return `${prev} ${curr.msg}`;
          }
          return prev;
        }, '');
      } else {
        errorMessage = error?.message;
      }
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
