import React, { useState, useMemo, useEffect } from 'react';
import { COLORS } from '../../../utils/colors';
import {
  QuickbooksInvoice,
  roundTo,
  formatMoney,
  capitalizeWordsInSentence,
  LienApiPostBody,
  DillPaymentMethod,
  GroupedLienAutoRelease,
  Job,
  LienTemplate,
  ReleaseFieldData,
  ReleaseTemplateField,
  calculateStripePartialFacilitationFeesAndAmount,
  appDelay,
  getOrdinalSuffix,
} from '@dill/dill-shared';
import { AppCreateRealease } from '../AppCreateRealease/AppCreateRealease';
import { AppButton } from '../AppButton/AppButton';
import moment from 'moment';
import emptyListImg from '../../../assets/images/emptyList.png';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Plus, Loader, X, AlertOctagon } from 'react-feather';
import {
  Checks,
  Bank,
  CaretLeft,
  CreditCard,
  LightbulbFilament,
  Warning,
  Trash,
  CaretDown,
  CaretUp,
} from 'phosphor-react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import { AppDropDown } from '../AppDropDown/AppDropDown';
import { AppRadioButton } from '../AppRadioButton/AppRadioButton';
import './AppPaymentInvoiceTable.scss';
import { twMerge } from 'tailwind-merge';
import { getJobs, updateJob } from '../../../redux/services/jobsService';
import { updateSubSupplier } from '../../../redux/services/subSuppliersService';
import AppAddPaymentMethodModal from '../AppAddPaymentMethodModal/AppAddPaymentMethodModal';
import {
  deletePaymentMethodByMainBuyer,
  dwollaTransactionLimitNotifyDill,
  getMainBuyerPaymentMethods,
} from '../../../redux/services/paymentsService';
import AppVerifyDwollaMicroTransactionsModal from '../AppVerifyDwollaMicroTransactionsModal/AppVerifyDwollaMicroTransactionsModal';
import { useParamMainBuyerId } from '../../../utils/hooks/useParamMainBuyerId';
import { closeMessageModal, openMessageModal } from '../../../redux/globalSlices/genericSlice';
import { AppInputField } from '../AppInputField/AppInputField';
import AppFilePicker from '../AppFilePicker/AppFilePicker';

export const AppPaymentInvoiceTable = ({
  handleClose,
  editedInvoices,
  selectedBuyerId,
  paymentTo,
  paymentFrom,
  onChangeModalType,
  modalType,
  onReleaseDetails,
  onPaymentDetail,
  onChangeInput,
  onBlurInput,
  totalPay,
  onToggleIsPaymentMethod,
  isSelectPaymentMethod,
  sendRealese,
  onAutoGenerateRelease,
  fees,
  paymentMethods,
  selectedPaymentMethod,
  onSelectPaymentMethod,
  totalCreditsToUse,
  creditAmount,
  onSetCreditToUse,
  isUseCredits,
  onUseCredits,
  selectedRelease,
  isPartialPayment,
  groupedInvoiceReleases,
  partialReason,
  onAutoAdjustInvoicesToFitLimit,
  onDeleteEditedInvoice,
  onPartialPaymentDetailsChange,
}: {
  handleClose: () => void;
  editedInvoices: {
    editedPayment?: number;
    formattedEditedPaymentValue?: string;
    invoiceOriginal: QuickbooksInvoice;
    discountAmount?: number;
  }[];
  selectedBuyerId: string | undefined;
  paymentTo: string | undefined;
  paymentFrom: string | undefined;
  modalType: string;
  onChangeModalType: (value: string) => void;
  onDeleteEditedInvoice: (value: number) => void;
  onAutoAdjustInvoicesToFitLimit: () => void;
  onReleaseDetails: (
    details: LienApiPostBody[],
    data: {
      [x: string]: {
        job: Job;
        originalJob: Job;
        template: LienTemplate | null;
        currentPdfUrl: string;
        originalPdfUrl: string;
        subSupplierPosition: number | null;
        amount: number;
        formFields: { [x: string]: ReleaseFieldData };
        templateFields: ReleaseTemplateField[] | null;
      };
    }
  ) => void;
  onPaymentDetail: (
    credit: number,
    paymentMethodId: string,
    paymentReason: string,
    editedInvoices: {
      editedPayment?: number;
      formattedEditedPaymentValue?: string;
      invoiceOriginal: QuickbooksInvoice;
      discountAmount?: number;
    }[]
  ) => void;
  onChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurInput: (event: React.FocusEvent<HTMLInputElement>) => void;
  totalPay: number;
  onToggleIsPaymentMethod: (value: boolean) => void;
  isSelectPaymentMethod: boolean;
  sendRealese: boolean;
  fees: number;
  onAutoGenerateRelease: () => void;
  paymentMethods: DillPaymentMethod[];
  selectedPaymentMethod: string;
  onSelectPaymentMethod: (val: string) => void;
  totalCreditsToUse: number;
  creditAmount: number;
  onSetCreditToUse: (val: number) => void;
  isUseCredits: boolean;
  onUseCredits: (value: boolean) => void;
  selectedRelease: {
    [x: string]: {
      job: Job;
      originalJob: Job;
      template: LienTemplate | null;
      currentPdfUrl: string;
      originalPdfUrl: string;
      subSupplierPosition: number | null;
      amount: number;
      formFields: { [x: string]: ReleaseFieldData };
      templateFields: ReleaseTemplateField[] | null;
    };
  };
  isPartialPayment: boolean;
  groupedInvoiceReleases: GroupedLienAutoRelease[];
  partialReason: string;
  onPartialPaymentDetailsChange: ({
    notes,
    urls,
    files,
  }: {
    notes?: string;
    urls?: {
      name: string;
      url: string;
    }[];
    files?: File[];
  }) => void;
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedMainBuyer } = useAppSelector((state) => state.buyers);
  const [invoiceListErrorMessage, setInvoiceListErrorMessage] = useState('');
  const [isAutoAdjustInvoicesToFitLimit, setIsAutoAdjustInvoicesToFitLimit] = useState(false);
  const [releaseData, setReleaseData] = useState<LienApiPostBody[]>([]);
  const [partialPaymentReason, setPartialPaymentReason] = useState('');
  const [partialPaymentNotes, setPartialPaymentNotes] = useState('');
  const [partialPaymentAttachmentsUrls, setPartialPaymentAttachmentsUrls] = useState<
    {
      name: string;
      url: string;
    }[]
  >([]);
  const [partialPaymentAttachmentsFiles, setPartialPaymentAttachmentsFiles] = useState<File[]>([]);
  const [releaseErrors, setReleaseErrors] = useState<{
    [key: string]: { [key: string]: string };
  }>({});
  const [jobOwner, setJobOwner] = useState('');
  const [updateJobOwner, setUpdateJobOwner] = useState(false);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [isAppAddPaymentMethodModalOpen, setIsAppAddPaymentMethodModalOpen] = useState(false);
  const [
    isAppVerifyDwollaMicroTransactionsModalOpen,
    setIsAppVerifyDwollaMicroTransactionsModalOpen,
  ] = useState(false);
  const [
    selectedPaymentMethodToVerifyMicroTransactions,
    setSelectedPaymentMethodToVerifyMicroTransactions,
  ] = useState<DillPaymentMethod>();
  const [releaseGroupDetails, setReleaseGroupDetails] = useState<{
    [x: string]: {
      job: Job;
      originalJob: Job;
      template: LienTemplate | null;
      currentPdfUrl: string;
      originalPdfUrl: string;
      subSupplierPosition: number | null;
      amount: number;
      formFields: { [x: string]: ReleaseFieldData };
      templateFields: ReleaseTemplateField[] | null;
    };
  }>({});
  const [vendorDetail, setVendorDetail] = useState<{
    [x: string]: {
      address: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
    };
  }>({});
  const [updatedInvoice, setUpdatedInvoice] = useState<
    {
      editedPayment?: number;
      formattedEditedPaymentValue?: string;
      invoiceOriginal: QuickbooksInvoice;
      discountAmount?: number;
    }[]
  >([]);
  const [openDiscountDropDown, setOpenDiscountDropDown] = useState(false);
  const { selectedSupplierForBuyer } = useAppSelector((state) => state.invoice);
  const { loadingList, paymentError, weekLimitations } = useAppSelector((state) => state.payments);

  const columns = useMemo(() => {
    const currentColumns = [
      { id: 'docNumber', name: 'Invoice #', width: 120, cellType: 'VIEW_TEXT' },
      { id: 'job', name: 'Job', width: 180, cellType: 'VIEW_TEXT' },
      { id: 'dueDate', name: 'Due Date', width: 180, cellType: 'VIEW_TEXT' },
      { id: 'balance', name: 'Open Amount', width: 180, cellType: 'VIEW_TEXT' },
      { id: 'payment', name: 'Payment', width: 180, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 80, cellType: 'VIEW_TEXT' },
    ];
    return currentColumns;
  }, [editedInvoices]);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const partialPaymentReasons = useMemo(() => {
    const result: { label: string; value: string }[] = [];
    if (
      selectedSupplierForBuyer?.partialPaymentReasons &&
      selectedSupplierForBuyer?.partialPaymentReasons.length > 0
    ) {
      for (let index = 0; index < selectedSupplierForBuyer?.partialPaymentReasons.length; index++) {
        const element = selectedSupplierForBuyer?.partialPaymentReasons[index];
        result.push({
          value: element,
          label: element,
        });
      }
      if (!selectedSupplierForBuyer?.partialPaymentReasons.includes('Other')) {
        result.push({
          value: 'Other',
          label: 'Other',
        });
      }
    }
    return result;
  }, [selectedSupplierForBuyer]);

  const creditCardTakeRate = useMemo(() => {
    const amount = totalPay - totalCreditsToUse;
    const buyer = user?.userBuyers?.find(
      (buy) =>
        buy.supplierId === selectedSupplierForBuyer?.id &&
        selectedMainBuyer &&
        selectedMainBuyer.quickBooksCustomerId === buy.quickbooksCustomerId
    );
    let calculateAmount = {} as {
      supplierFees: number;
      buyerFees: number;
      amount: number;
      amountToPay: number;
      totalFees: number;
    };
    if (selectedSupplierForBuyer && buyer) {
      calculateAmount = calculateStripePartialFacilitationFeesAndAmount({
        amount: 100,
        supplier: { ...selectedSupplierForBuyer, creditCardTakeRateExtra: 0 },
        buyer: { ...buyer, creditCardTakeRateExtra: 0 },
      });
    }
    return calculateAmount?.buyerFees ?? 0;
  }, [selectedPaymentMethod]);

  const showBankLimit = useMemo(() => {
    const result = paymentMethods.find((method) => method.id === selectedPaymentMethod);
    if (result) {
      return result.type === 'BANK_ACCOUNT';
    }
    return false;
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (editedInvoices && editedInvoices.length > 0) {
      setInvoiceListErrorMessage('');
    }
  }, [editedInvoices]);

  const handleCloseModal = () => {
    setOpenDiscountDropDown(false);
    handleClose();
  };

  const checkCityStateZipCode = (temp: LienTemplate | null) => {
    if (temp) {
      const hasAddress = [...temp.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorCityStateZip')
      );
      return hasAddress.length > 0;
    }

    return false;
  };

  const checkVendorAddress = (temp: LienTemplate | null) => {
    if (temp) {
      const hasAddress = [...temp.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorAddress')
      );
      return hasAddress.length > 0;
    }

    return false;
  };

  const checkVendorPhone = (temp: LienTemplate | null) => {
    if (temp) {
      const hasPhone = [...temp.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' &&
          formFields.whoPopulatesField === 'BUYER' &&
          formFields.id.startsWith('vendorPhone')
      );
      return hasPhone.length > 0;
    }

    return false;
  };

  const checkJobOwner = (job: Job) => {
    if (job) {
      if (!job?.owner || job?.owner === '') {
        return true;
      }
    }
    return false;
  };

  const checkJobAddressCityStateZip = (job: Job, type: 'address' | 'city' | 'state' | 'zip') => {
    if (job) {
      if ((!job?.address || job?.address.trim() === '') && type === 'address') {
        return true;
      }
      if ((!job?.city || job?.city.trim() === '') && type === 'city') {
        return true;
      }
      if ((!job?.state || job?.state.trim() === '') && type === 'state') {
        return true;
      }
      if (!job?.zipCode && type === 'zip') {
        return true;
      }
    }
    return false;
  };

  const hasAddressJobCityStateZip = (job: Job) => {
    if (job) {
      if (
        !job?.city ||
        job?.city === '' ||
        !job?.state ||
        job?.state === '' ||
        !job?.zipCode ||
        !job?.address ||
        job?.address === ''
      ) {
        return true;
      }
    }
    return false;
  };

  const checkJobAddressCityStateZipChanges = (
    job: Job,
    originalJob: Job,
    type: 'address' | 'city' | 'state' | 'zip'
  ) => {
    if (job && originalJob) {
      if (type === 'address' && job?.address !== originalJob?.address) {
        return true;
      }
      if (type === 'city' && job?.city !== originalJob?.city) {
        return true;
      }
      if (type === 'state' && job?.state !== originalJob?.state) {
        return true;
      }
      if (type === 'zip' && originalJob?.zipCode !== originalJob?.zipCode) {
        return true;
      }
    }
    return false;
  };

  const handleUpdateRelease = async () => {
    if (releaseGroupDetails && selectedMainBuyerId) {
      const releaseGroupKeys = Object.keys(releaseGroupDetails).length;
      if (releaseGroupKeys > 0) {
        Object.entries(releaseGroupDetails).forEach(async ([key, value]) => {
          const jobOwnerRequired = checkJobOwner(value.originalJob);
          const hasCityStateZipCode = checkCityStateZipCode(value.template);
          const hasVendorAddress = checkVendorAddress(value.template);
          const hasVendorPhone = checkVendorPhone(value.template);

          const checkJobAddressChanges = checkJobAddressCityStateZipChanges(
            value.job,
            value.originalJob,
            'address'
          );
          const checkJobCityChanges = checkJobAddressCityStateZipChanges(
            value.job,
            value.originalJob,
            'city'
          );
          const checkJobStateChanges = checkJobAddressCityStateZipChanges(
            value.job,
            value.originalJob,
            'state'
          );
          const checkJobZipChanges = checkJobAddressCityStateZipChanges(
            value.job,
            value.originalJob,
            'zip'
          );

          const updateJobAddress = hasAddressJobCityStateZip(value.originalJob);

          let updateJobDetails = {};
          if (jobOwnerRequired) {
            updateJobDetails = { ...updateJobDetails, owner: value.job?.owner ?? '' };
          }
          if (updateJobAddress && checkJobAddressChanges) {
            updateJobDetails = { ...updateJobDetails, address: value.job?.address ?? '' };
          }
          if (updateJobAddress && checkJobCityChanges) {
            updateJobDetails = { ...updateJobDetails, city: value.job?.city ?? '' };
          }
          if (updateJobAddress && checkJobStateChanges) {
            updateJobDetails = { ...updateJobDetails, state: value.job?.state ?? '' };
          }
          if (updateJobAddress && checkJobZipChanges) {
            updateJobDetails = { ...updateJobDetails, zipCode: Number(value.job?.address) ?? null };
          }

          if (jobOwnerRequired || updateJobAddress) {
            const updateJobResult = await dispatch(
              updateJob({
                mainBuyerId: selectedMainBuyerId,
                jobId: value.job?.id ?? '',
                jobDetails: { ...updateJobDetails },
              })
            );
            if (updateJobResult.type === 'jobs/updateJob/fulfilled') {
              await dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
            }
          }
          let updateVendorDetails = {};
          if (hasCityStateZipCode) {
            updateVendorDetails = {
              ...updateVendorDetails,
              city: vendorDetail[key].city,
              state: vendorDetail[key].state,
              zipCode: Number(vendorDetail[key].zip),
            };
          }
          if (hasVendorAddress) {
            updateVendorDetails = { ...updateVendorDetails, address: vendorDetail[key].address };
          }
          if (hasVendorPhone && value.formFields) {
            const vendorPhoneEntry = Object.entries(value.formFields).find(([key2, value2]) =>
              key2.startsWith('vendorPhone')
            );
            if (vendorPhoneEntry) {
              const [vendorPhoneKey, vendorPhoneObject] = vendorPhoneEntry;
              updateVendorDetails = {
                ...updateVendorDetails,
                phoneNumber: vendorPhoneObject?.value ?? '',
              };
            }
          }
          if (Object.keys(updateVendorDetails).length > 0) {
            await dispatch(
              updateSubSupplier({
                mainBuyerId: selectedMainBuyerId || '',
                subSupplierDetails: { ...updateVendorDetails },
                subSupplierId: releaseData[Number(key)]?.subSupplierId ?? '',
              })
            );
          }
        });
      }
    }
  };

  const handleError = () => {
    let error: { [key: string]: { [key: string]: string } } = {};
    Object.entries(releaseGroupDetails).forEach(([key, value]) => {
      const jobOwnerRequired = checkJobOwner(value.originalJob);
      const hasJobOwner = checkJobOwner(value.job);
      const hasJobAddress = checkJobAddressCityStateZip(value.job, 'address');
      const hasJobCity = checkJobAddressCityStateZip(value.job, 'city');
      const hasJobState = checkJobAddressCityStateZip(value.job, 'state');
      const hasJobZip = checkJobAddressCityStateZip(value.job, 'zip');
      const hasVendorAddress = checkVendorAddress(value.template);
      const hasCityStateZipCode = checkCityStateZipCode(value.template);
      let fields: string[] = [];
      if (jobOwnerRequired && hasJobOwner) {
        error = { ...error, [key]: { ...error[key], owner: 'Owner is required' } };
      }
      if (hasJobAddress) {
        error = { ...error, [key]: { ...error[key], address: 'Job address is required' } };
      }
      if (hasJobCity) {
        error = { ...error, [key]: { ...error[key], city: 'Job city is required' } };
      }
      if (hasJobState) {
        error = { ...error, [key]: { ...error[key], state: 'Job state is required' } };
      }
      if (hasJobZip) {
        error = { ...error, [key]: { ...error[key], zipCode: 'Job zip code is required' } };
      }
      if (value.job === null) {
        error = { ...error, [key]: { ...error[key], job: 'Select job' } };
      }
      if (value.template === null) {
        error = { ...error, [key]: { ...error[key], template: 'Select template' } };
      }
      if (value.subSupplierPosition === null) {
        error = { ...error, [key]: { ...error[key], supplier: 'Select supplier' } };
      }
      if (
        hasVendorAddress &&
        (!vendorDetail[key]?.address ||
          vendorDetail[key]?.address === null ||
          vendorDetail[key]?.address === '')
      ) {
        fields = [...fields, 'address'];
      }
      if (
        hasCityStateZipCode &&
        (!vendorDetail[key]?.city ||
          vendorDetail[key]?.city === null ||
          vendorDetail[key]?.city === '')
      ) {
        fields = [...fields, 'city'];
      }
      if (
        hasCityStateZipCode &&
        (!vendorDetail[key]?.state ||
          vendorDetail[key]?.state === null ||
          vendorDetail[key]?.state === '')
      ) {
        fields = [...fields, 'state'];
      }
      if (
        hasCityStateZipCode &&
        (!vendorDetail[key]?.zip ||
          vendorDetail[key]?.zip === null ||
          vendorDetail[key]?.zip === '')
      ) {
        fields = [...fields, 'zipCode'];
      }
      if (value.formFields) {
        Object.keys(value.formFields).forEach((fieldKey) => {
          const fieldData = value.formFields[fieldKey];
          if (
            fieldData.isRequired &&
            !fieldData.value &&
            !fieldData.id.startsWith('vendorCityStateZip') &&
            !fieldData.id.startsWith('vendorAddress')
          ) {
            fields = [...fields, fieldKey];
          }
        });
      }
      for (let index = 0; index < fields.length; index++) {
        const field = fields[index];
        error = { ...error, [key]: { ...error[key], [field]: 'Required' } };
      }
    });
    return error;
  };

  const handlePayment = async () => {
    const releaseError = handleError();
    setReleaseErrors(releaseError);
    if (Object.keys(releaseError).length > 0 && isSelectPaymentMethod && sendRealese) {
      return;
    }
    if (selectedPaymentMethod == '' && isSelectPaymentMethod) {
      return;
    }
    if (isSelectPaymentMethod) {
      if (
        weekLimitations &&
        totalPay - totalCreditsToUse + fees > weekLimitations?.weekLimit &&
        showBankLimit
      ) {
        setInvoiceListErrorMessage(
          `Total payment is greater than this week's limit of ${formatMoney(
            weekLimitations?.weekLimit,
            2
          )}. Please auto-adjust the invoices to complete payment, and we will send you a reminder next week to pay the rest.`
        );
        dispatch(
          dwollaTransactionLimitNotifyDill({
            buyerId: selectedBuyerId || '',
            supplierId: selectedSupplierForBuyer?.id || '',
          })
        );
        setIsAutoAdjustInvoicesToFitLimit(true);
      } else {
        if (sendRealese) {
          onReleaseDetails(releaseData, releaseGroupDetails);
        }
        setInvoiceListErrorMessage('');
        onChangeModalType('paymentConfirmation');
        handleUpdateRelease();
        onPaymentDetail(
          totalCreditsToUse,
          selectedPaymentMethod,
          partialPaymentReason,
          updatedInvoice
        );
      }
    } else if (isPartialPayment && partialPaymentReason === '') {
      setInvoiceListErrorMessage('Please select a reason for partial payment');
    } else if (modalType === 'invoiceList') {
      onToggleIsPaymentMethod(true);
    } else {
      onToggleIsPaymentMethod(true);
      setInvoiceListErrorMessage('');
    }
  };

  const handleCreateRelease = (
    data: {
      [x: string]: {
        job: Job;
        originalJob: Job;
        template: LienTemplate | null;
        currentPdfUrl: string;
        originalPdfUrl: string;
        subSupplierPosition: number | null;
        amount: number;
        formFields: { [x: string]: ReleaseFieldData };
        templateFields: ReleaseTemplateField[] | null;
      };
    },
    vendorDetails: {
      [x: string]: {
        address: string | null;
        city: string | null;
        state: string | null;
        zip: string | null;
      };
    },
    releaseArray: LienApiPostBody[]
  ) => {
    setReleaseGroupDetails(data);
    setVendorDetail(vendorDetails);
    setReleaseData(releaseArray);
  };

  useEffect(() => {
    if (partialReason) {
      setPartialPaymentReason(partialReason);
    }
  }, []);

  useEffect(() => {
    onPartialPaymentDetailsChange({
      notes: partialPaymentNotes,
      urls: partialPaymentAttachmentsUrls,
      files: partialPaymentAttachmentsFiles,
    });
  }, [partialPaymentNotes, partialPaymentAttachmentsUrls, partialPaymentAttachmentsFiles]);

  const handleDeletePaymentMethod = async (paymentMethod: DillPaymentMethod) => {
    dispatch(
      openMessageModal({
        buttonText: 'Cancel',
        isSecondButton: true,
        secondButtonText: 'Delete',
        modalType: 'WARN',
        title: `Are you sure you want to delete this payment method?`,
        subTitle: 'This action can not be undone.',
        onSecondButtonClick: async () => {
          await appDelay(10);
          dispatch(
            openMessageModal({
              buttonText: '',
              modalType: 'LOADING',
              title: 'Deleting',
              subTitle: 'Deleting payment method',
            })
          );
          const deletePaymentMethodResults = await dispatch(
            deletePaymentMethodByMainBuyer({
              mainBuyerId: selectedMainBuyerId || '',
              paymentMethodId: paymentMethod.id,
            })
          );
          if (deletePaymentMethodResults.type === 'payments/deletePaymentMethod/rejected') {
            let errorMessage = '';
            if (deletePaymentMethodResults.type === 'payments/deletePaymentMethod/rejected') {
              errorMessage = deletePaymentMethodResults.payload as any;
            }
            console.log({ errorMessage });
            dispatch(
              openMessageModal({
                buttonText: 'Close',
                modalType: 'ERROR',
                title: 'Error deleting payment method',
                subTitle: `${errorMessage} Contact 972-339-0799 for customer support`,
              })
            );
          } else if (deletePaymentMethodResults.type === 'payments/deletePaymentMethod/fulfilled') {
            dispatch(
              openMessageModal({
                buttonText: 'Close',
                modalType: 'SUCCESS',
                title: 'Payment method deleted',
                subTitle: `Payment method deleted successfully`,
                onClose: () => {
                  dispatch(getMainBuyerPaymentMethods({ mainBuyerId: selectedMainBuyerId || '' }));
                },
              })
            );
            setOpenDiscountDropDown(false);
          } else {
            closeMessageModal();
          }
        },
      })
    );
  };

  function calculateDiscount(invoice: {
    editedPayment?: number;
    formattedEditedPaymentValue?: string;
    invoiceOriginal: QuickbooksInvoice;
  }): number {
    const { terms, dueDate } = invoice.invoiceOriginal;

    if (
      invoice.editedPayment &&
      terms &&
      dueDate &&
      (terms.toLowerCase().trim() === '2% 10th, net 30' ||
        terms.toLowerCase().trim() === '2% 2nd 10th, net 60')
    ) {
      // Parse discount information from terms (e.g., "2% 10TH, NET 30")
      // const discountMatch = terms.match(/(\d+)%\s+(\d+)[a-zA-Z]*,\s+NET\s+(\d+)/);
      // if (!discountMatch) return 0;

      // const discountPercentage = parseFloat(discountMatch[1]);
      const discountPercentage = 2;
      const discountDays = 10;

      // Calculate the dates
      const dueDateMoment = moment(dueDate);
      const discountEndDate = moment(
        `${dueDateMoment.year()}-${dueDateMoment.month() + 1}-${discountDays}`,
        'YYYY-MM-DD'
      ).endOf('day');
      const paymentDate = moment();

      // Check if the payment was made within the discount period
      if (paymentDate.isSameOrBefore(discountEndDate)) {
        return (invoice.editedPayment * discountPercentage) / 100;
      }
    }
    return 0;
  }

  function getDiscountTerms(invoice: {
    editedPayment?: number;
    formattedEditedPaymentValue?: string;
    invoiceOriginal: QuickbooksInvoice;
  }): string {
    const { terms, dueDate } = invoice.invoiceOriginal;

    if (
      invoice.editedPayment &&
      terms &&
      dueDate &&
      (terms.toLowerCase().trim() === '2% 10th, net 30' ||
        terms.toLowerCase().trim() === '2% 2nd 10th, net 60')
    ) {
      // Parse discount information from terms (e.g., "2% 10TH, NET 30")
      // const discountMatch = terms.match(/(\d+)%\s+(\d+)[a-zA-Z]*,\s+NET\s+(\d+)/);
      // if (!discountMatch) return '';

      // const discountPercentage = parseFloat(discountMatch[1]);
      const discountPercentage = 2;
      const discountDays = 10;

      // Calculate the dates
      const dueDateMoment = moment(dueDate);
      const discountEndDate = moment(
        `${dueDateMoment.year()}-${dueDateMoment.month() + 1}-${discountDays}`,
        'YYYY-MM-DD'
      ).endOf('day');
      const paymentDate = moment();

      // Check if the payment was made within the discount period
      if (paymentDate.isSameOrBefore(discountEndDate)) {
        const newTerm = terms.split(', N');
        return `${newTerm[0]}`;
      }
    }
    return '';
  }

  function calculateDiscountsForInvoices(
    invoices: {
      editedPayment?: number;
      formattedEditedPaymentValue?: string;
      invoiceOriginal: QuickbooksInvoice;
    }[]
  ): number {
    const updateInv = invoices.map((inv) => {
      return { ...inv, discountAmount: calculateDiscount(inv) };
    });
    setUpdatedInvoice(updateInv);
    return invoices.reduce((total, invoice) => total + calculateDiscount(invoice), 0);
  }

  const discountPayment = useMemo(() => {
    if (
      (selectedSupplierForBuyer?.integrationType === 'MANUAL_UPLOAD' ||
        selectedSupplierForBuyer?.integrationType === 'ABLE_MANUAL_UPLOAD') &&
      selectedMainBuyer
    ) {
      const result = paymentMethods.find((method) => method.id === selectedPaymentMethod);
      if (result && result.type === 'BANK_ACCOUNT') {
        const discountAmount = calculateDiscountsForInvoices(editedInvoices);
        return discountAmount;
      } else {
        setUpdatedInvoice(editedInvoices);
      }
    } else {
      setUpdatedInvoice(editedInvoices);
    }
    return 0;
  }, [editedInvoices, selectedPaymentMethod]);

  const renderDiscountDropDown = () => {
    return (
      <div className="PRIMARY_300-BORDER border mb-2 rounded overflow-hidden">
        <div
          className="flex flex-row justify-between  cursor-pointer p-1 hover:bg-indigo-100"
          onClick={() => setOpenDiscountDropDown(!openDiscountDropDown)}>
          <div className="flex items-center justify-center">
            {openDiscountDropDown ? (
              <CaretUp color={COLORS.GREY_500} size={19} />
            ) : (
              <CaretDown color={COLORS.GREY_500} size={19} />
            )}
            <div className="amount-label-text ml-2">Discount</div>
          </div>
          <div className="amount-text">{`-${formatMoney(discountPayment, 2)}`}</div>
        </div>
        {openDiscountDropDown && (
          <div className="WHITE-BG p-2">
            <div className="w-full px-[4px] py-1 flex justify-between PRIMARY_100-BG">
              <div className="text-xs font-bold">Invoice No</div>
              <div className="flex">
                <div className="text-xs w-[100px] font-bold">Due Date</div>
                <div className="text-xs w-[120px] font-bold">Amount</div>
                <div className="text-xs w-[100px] font-bold">Terms</div>
                <div className="text-xs w-[100px] flex justify-end font-bold">Discount</div>
              </div>
            </div>
            {editedInvoices.map((invoice) => {
              const discount = calculateDiscount(invoice);
              return (
                <div
                  className="w-full px-[2px] py-1 flex justify-between"
                  key={`dropdown_discount_${invoice.invoiceOriginal.id}`}>
                  <div className="text-xs">{invoice.invoiceOriginal.id}</div>
                  <div className="flex">
                    <div className={`text-xs w-[100px] ${discount > 0 ? '' : 'ERROR_500-CLR'}`}>
                      {moment(invoice.invoiceOriginal.dueDate).format('MM/DD/YYYY')}
                    </div>
                    <div className="text-xs w-[120px]">{`${formatMoney(
                      invoice?.editedPayment ?? '',
                      2
                    )}`}</div>
                    <div className="text-xs w-[100px]">{getDiscountTerms(invoice)}</div>
                    <div className="text-xs w-[100px] flex justify-end">{`${
                      discount > 0 ? '-' : ''
                    }${formatMoney(discount, 2)}`}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="app-payment-invoice-table-wrapper ">
      <AppAddPaymentMethodModal
        userType="buyer"
        open={isAppAddPaymentMethodModalOpen}
        handleClose={() => {
          dispatch(getMainBuyerPaymentMethods({ mainBuyerId: selectedMainBuyerId || '' }));
          setIsAppAddPaymentMethodModalOpen(false);
        }}
      />
      <AppVerifyDwollaMicroTransactionsModal
        selectedPaymentMethod={selectedPaymentMethodToVerifyMicroTransactions}
        open={isAppVerifyDwollaMicroTransactionsModalOpen}
        handleClose={() => {
          dispatch(getMainBuyerPaymentMethods({ mainBuyerId: selectedMainBuyerId || '' }));
          setSelectedPaymentMethodToVerifyMicroTransactions(undefined);
          setIsAppVerifyDwollaMicroTransactionsModalOpen(false);
        }}
      />
      <div className="payment-modal-title-container sticky top-0 bg-white z-10">
        <div className="flex">
          <h3 className="font-medium text-lg">
            {isSelectPaymentMethod
              ? `${formatMoney(totalPay + fees, 2)}`
              : `${editedInvoices.length > 1 ? 'Bulk Pay' : 'Pay Invoice'}`}
          </h3>
        </div>
        <AppButton
          type="FLAT"
          buttonStyles={{ padding: '0px', margin: '5px 2px' }}
          icon={<X color={COLORS.GREY_300} size={30} />}
          onClick={handleCloseModal}
        />
      </div>
      <div className="payment-invoice-table-modal-container">
        <div className="flex flex-row">
          <div className="payment-invoice-table-modal-left-content ">
            <div className="flex flex-col mx-6 mt-4">
              <div className="flex flex-row w-full my-1">
                <p className="detailText w-1/5">To</p>
                <p className="respText w-4/5">{paymentTo}</p>
              </div>
              <div className="flex flex-row w-full my-1">
                <p className="detailText w-1/5">From</p>
                <p className="respText w-4/5">{paymentFrom}</p>
              </div>
            </div>
            <div className="flex flex-col px-4 mt-4 grow">
              {!isSelectPaymentMethod && (
                <div className="flex w-full flex-col overflow-y-scroll border max-h-64 rounded-xl">
                  <div className="flex border-b px-5 items-center w-full sticky top-0 PRIMARY_50-BG py-3">
                    {columns.map((column) => {
                      return (
                        <div
                          key={column.id}
                          style={{ flex: column.width / totalWidth }}
                          className="flex w-full ml-4  ">
                          {column.cellType !== 'SELECT' && (
                            <p className="TEXT_SECONDARY-CLR text-xs w-full text-left">
                              {column.name}
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  {editedInvoices.length < 1 && (
                    <div className="w-full h-full flex items-center justify-center">
                      <div className="flex flex-col items-center">
                        <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                        <p>No invoices</p>
                      </div>
                    </div>
                  )}
                  {editedInvoices.length > 0 &&
                    [...editedInvoices].map((invoiceEdited, invoiceIndex) => {
                      return (
                        <div key={invoiceIndex + 'oo'} className={'flex items-center px-2 my-2'}>
                          {columns.map((column) => {
                            type ObjectKey = keyof typeof invoiceEdited.invoiceOriginal;
                            const columnKey = column.id as ObjectKey;
                            let value = invoiceEdited.invoiceOriginal[columnKey]?.toString();
                            const columnInvoiceNo = invoiceEdited.invoiceOriginal.id?.toString();
                            if (column.id === 'createdAt' || column.id === 'dueDate') {
                              value = moment(value).utc().format('MM/DD/YYYY');
                            }
                            if (column.id === 'amount' || column.id === 'balance') {
                              value = `$${roundTo(parseFloat(value || '0'), 2)}`;
                            }
                            const accountBalance = invoiceEdited.formattedEditedPaymentValue;

                            return (
                              <div
                                key={column.id}
                                style={{ flex: column.width / totalWidth }}
                                className="flex items-center w-full h-full justify-center">
                                {column.cellType === 'VIEW_TEXT' &&
                                  column.id !== 'select' &&
                                  column.id !== 'download' &&
                                  column.id !== 'actions' &&
                                  column.id !== 'signedBy' &&
                                  column.id !== 'open' &&
                                  column.id !== 'balance' &&
                                  column.id !== 'amount' &&
                                  column.id !== 'job' &&
                                  column.id !== 'isPastDueDate' && (
                                    <p className={'py-3 text-center text-sm ' + 'TEXT_PRIMARY-CLR'}>
                                      {value}
                                    </p>
                                  )}
                                {column.cellType === 'VIEW_TEXT' && column.id === 'payment' && (
                                  <div className="edit-invoice-input">
                                    <div className="text-sm">$</div>
                                    <input
                                      type={'number'}
                                      className={'w-full text-sm ' + 'TEXT_PRIMARY-CLR'}
                                      name={columnInvoiceNo}
                                      value={accountBalance}
                                      onChange={onChangeInput}
                                      onBlur={(e) => onBlurInput(e)}
                                    />
                                  </div>
                                )}
                                {column.cellType === 'VIEW_TEXT' && column.id === 'balance' && (
                                  <p className={'text-sm w-full py-3 ml-4 ' + 'TEXT_PRIMARY-CLR'}>
                                    {value}
                                  </p>
                                )}
                                {column.cellType === 'VIEW_TEXT' &&
                                  column.id === 'job' &&
                                  invoiceEdited.invoiceOriginal.quickBooksJob && (
                                    <div className={'text-sm w-full'}>
                                      <p className="font-bold text-xs text-center">
                                        {invoiceEdited.invoiceOriginal.quickBooksJob?.jobName}
                                      </p>
                                      <p className="text-2xs text-center">
                                        {invoiceEdited.invoiceOriginal.quickBooksJob?.address ||
                                          invoiceEdited.invoiceOriginal.shipAddress?.address1}
                                      </p>
                                    </div>
                                  )}
                                {column.cellType === 'VIEW_TEXT' &&
                                  column.id !== 'select' &&
                                  column.id !== 'download' &&
                                  column.id !== 'actions' &&
                                  column.id !== 'isPastDueDate' &&
                                  column.id === 'signedBy' && (
                                    <div className="items-center justify-center ">
                                      {invoiceEdited.invoiceOriginal.dillInvoice &&
                                        invoiceEdited.invoiceOriginal.dillInvoice.isReceived && (
                                          <div className="items-center justify-center">
                                            {invoiceEdited.invoiceOriginal.dillInvoice
                                              .receivedBy && (
                                              <p
                                                className={
                                                  'text-xs font-bold text-center  ' +
                                                  'TEXT_PRIMARY-CLR '
                                                }>
                                                {
                                                  invoiceEdited.invoiceOriginal.dillInvoice
                                                    .receivedBy
                                                }
                                              </p>
                                            )}
                                            {invoiceEdited.invoiceOriginal.dillInvoice
                                              .receivedByTime && (
                                              <p
                                                className={
                                                  'text-xs text-center ' + 'TEXT_PRIMARY-CLR'
                                                }>
                                                {moment(
                                                  invoiceEdited.invoiceOriginal.dillInvoice
                                                    .receivedByTime
                                                ).format('MM/DD  LT')}
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      {(!invoiceEdited.invoiceOriginal.dillInvoice ||
                                        (invoiceEdited.invoiceOriginal.dillInvoice &&
                                          !invoiceEdited.invoiceOriginal.dillInvoice
                                            .isReceived)) && (
                                        <p className={'text-sm  text-center ' + 'TEXT_PRIMARY-CLR'}>
                                          -
                                        </p>
                                      )}
                                    </div>
                                  )}
                                {column.cellType === 'VIEW_TEXT' &&
                                  column.id !== 'select' &&
                                  column.id !== 'download' &&
                                  column.id !== 'actions' &&
                                  column.id === 'isPastDueDate' && (
                                    <div
                                      style={{ width: '100px' }}
                                      className={
                                        value === 'true'
                                          ? 'rounded-lg text-sm SUCCESS_100-BG p-2 SUCCESS_600-CLR text-center'
                                          : 'rounded-lg text-sm WARNING_100-BG p-2 WARNING_600-CLR text-center'
                                      }>
                                      {value === 'true' ? 'Past Due' : 'Current Due'}
                                    </div>
                                  )}
                                {column.id === 'actions' && (
                                  <div className={''}>
                                    <Trash
                                      size={20}
                                      className="cursor-pointer"
                                      color={COLORS.ERROR_500}
                                      onClick={() => {
                                        onDeleteEditedInvoice(invoiceIndex);
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </div>
              )}
              {!isSelectPaymentMethod && isPartialPayment && (
                <div className="mt-2 flex flex-col">
                  <div className="">
                    <AppDropDown
                      isRequired={true}
                      items={partialPaymentReasons}
                      value={partialPaymentReason}
                      onSelectChange={(data) => {
                        setPartialPaymentReason(data?.value ?? '');
                        setInvoiceListErrorMessage('');
                      }}
                      label="Select Partial Payment Reason"
                      placeholder="Select a partial payment reason"
                    />
                  </div>
                  <div className="">
                    <AppInputField
                      label="Partial Payment Notes"
                      inputType="textarea"
                      value={partialPaymentNotes}
                      placeholder="Partial payment notes"
                      onTextChange={(val) => {
                        setPartialPaymentNotes(val);
                      }}
                    />
                  </div>
                  <div className="">
                    <div className="my-2">
                      <div className="text-xs mb-0.5  whitespace-nowrap font-medium">
                        Partial Payment Attachments
                      </div>
                      <AppFilePicker
                        onSelectFile={(
                          uploadedFiles: File[],
                          fileUrls?: { name: string; url: string }[]
                        ) => {
                          setPartialPaymentAttachmentsFiles(uploadedFiles);
                          if (fileUrls) {
                            setPartialPaymentAttachmentsUrls(fileUrls);
                          }
                        }}
                        fileUrls={partialPaymentAttachmentsUrls}
                      />
                    </div>
                  </div>
                </div>
              )}
              {isSelectPaymentMethod && totalPay - totalCreditsToUse + fees !== 0 && (
                <div className="flex flex-col h-full">
                  <div className="flex flex-row items-center justify-between ml-3">
                    <p className="text-sm font-bold ">Select a Payment Method</p>
                    <AppButton
                      text="New Method"
                      type="FLAT"
                      icon={<Plus size={16} color={COLORS.PRIMARY_500} />}
                      onClick={() => {
                        // onChangeModalType('newPaymentMethod');
                        setIsAppAddPaymentMethodModalOpen(true);
                      }}
                    />
                  </div>
                  <div className="flex flex-col payment-options-container grow overflow-y-scroll">
                    {paymentMethods.map((paymentMethod, i) => {
                      return (
                        <div
                          key={`${paymentMethod.id}_list`}
                          className={'w-full px-4 my-1 relative'}
                          data-tooltip-id={`payment-method-tooltip_${paymentMethod.id}`}>
                          <div className="absolute right-5 top-2">
                            <Trash
                              size={16}
                              className="cursor-pointer"
                              color={COLORS.ERROR_500}
                              onClick={() => {
                                handleDeletePaymentMethod(paymentMethod);
                              }}
                            />
                          </div>
                          <div
                            className={twMerge(
                              'flex flex-row rounded-xl  px-5 py-4 border justify-between items-center cursor-pointer ',
                              paymentMethod.id === selectedPaymentMethod
                                ? 'PRIMARY_500-BORDER'
                                : ' GREY_300-BORDER',
                              paymentMethod.status === 'MICRO_DEPOSITS_SENT' ||
                                paymentMethod.status === 'MICRO_DEPOSITS_VERIFICATION_PENDING' ||
                                paymentMethod.status === 'UNVERIFIED'
                                ? 'bg-gray-100'
                                : 'WHITE-BG'
                            )}
                            onClick={() => {
                              setIsAutoAdjustInvoicesToFitLimit(false);
                              if (!paymentMethod?.status) {
                                onSelectPaymentMethod(paymentMethod.id);
                                setInvoiceListErrorMessage('');
                                setOpenDiscountDropDown(false);
                              }
                            }}>
                            <div className="flex flex-row ">
                              <div className="flex mr-2 items-center">
                                <AppRadioButton
                                  isChecked={paymentMethod.id === selectedPaymentMethod}
                                />
                                <div className="payment-type-logo">
                                  {paymentMethod.type === 'BANK_ACCOUNT' ? (
                                    <Bank
                                      size={30}
                                      style={{ display: 'flex', alignSelf: 'center' }}
                                    />
                                  ) : (
                                    <CreditCard
                                      size={30}
                                      style={{ display: 'flex', alignSelf: 'center' }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-col">
                                {paymentMethod.type === 'BANK_ACCOUNT' ? (
                                  <div className="text-sm font-bold text-left">Bank Transfer</div>
                                ) : (
                                  <div className="text-sm font-bold text-left">
                                    Credit/Debit Card
                                  </div>
                                )}
                                <p className="accountDetails">{`${paymentMethod.paymentMethodName}`}</p>
                              </div>
                            </div>

                            {paymentMethod.type === 'CREDIT_CARD' &&
                            creditCardTakeRate &&
                            creditCardTakeRate > 0 ? (
                              <div className="flex-end">
                                <div className="fees-label">Fees</div>
                                <div className="credit-card-fees">{`~${creditCardTakeRate}%`}</div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {(paymentMethod.status === 'MICRO_DEPOSITS_SENT' ||
                              paymentMethod.status === 'MICRO_DEPOSITS_VERIFICATION_PENDING' ||
                              paymentMethod.status === 'UNVERIFIED') && (
                              <div className="flex-end">
                                <div className="text-xs flex items-center mb-[2px]">
                                  <Warning size={18} color={COLORS.ERROR_500} className="mr-1" />
                                  Unverified
                                </div>
                                <div className="">
                                  <AppButton
                                    text="Verify Deposits"
                                    isDisabled={
                                      paymentMethod.status !== 'MICRO_DEPOSITS_VERIFICATION_PENDING'
                                    }
                                    onClick={() => {
                                      setIsAppVerifyDwollaMicroTransactionsModalOpen(true);
                                      setSelectedPaymentMethodToVerifyMicroTransactions(
                                        paymentMethod
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="payment-limit-container">
                    {weekLimitations && showBankLimit && (
                      <p className="text-xs font-thin PRIMARY_500-CLR ">{`Limit: ${formatMoney(
                        weekLimitations.weekLimit,
                        2
                      )}`}</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="flex flex-col w-full ">
              {!isSelectPaymentMethod && (
                <div className="w-full px-4 my-4">
                  <div className="flex flex-col  mt-4 rounded-xl PRIMARY_50-BG px-5 py-4">
                    {isUseCredits && (
                      <div className="credit-display-container">
                        <div className="flex flex-row justify-between  mb-2 ">
                          <div className="amount-label-text">Amount</div>
                          <div className="amount-text">{`${formatMoney(totalPay, 2)}`}</div>
                        </div>
                        <div className="flex flex-row justify-between  mb-2 ">
                          <div className="amount-label-text">Credits</div>
                          <div className="amount-text">{`-${formatMoney(
                            totalCreditsToUse,
                            2
                          )}`}</div>
                        </div>
                      </div>
                    )}
                    <div className="flex flex-row justify-between   ">
                      <p className="amount-text">Total Payment</p>
                      <div className="flex flex-col items-end">
                        <p className="totalText">{`${formatMoney(
                          totalPay - totalCreditsToUse,
                          2
                        )}`}</p>
                      </div>
                    </div>
                  </div>
                  {!selectedSupplierForBuyer?.integrationType &&
                    selectedSupplierForBuyer?.invoices?.creditMemosDetails.totalRemainingCredit !==
                      undefined &&
                    selectedSupplierForBuyer?.invoices?.creditMemosDetails.totalRemainingCredit >
                      0 && (
                      <div
                        className="mt-3 flex relative items-center w-1/3"
                        data-tooltip-id={'credits_soon'}>
                        {selectedSupplierForBuyer.integrationType === 'CODAT_NETSUITE' && (
                          //  && totalPay - creditAmount === 0
                          <div className="absolute bg-white opacity-50 h-full w-full"></div>
                        )}
                        <div className="absolute bg-white opacity-50 h-full w-full"></div>
                        <ReactTooltip
                          id={'credits_soon'}
                          place="right"
                          variant="dark"
                          opacity={'100%'}
                          style={{
                            background: '#222',
                            width: '200px',
                            zIndex: 99999,
                          }}>
                          <span>Applying credits will be available on the Dill portal soon</span>
                        </ReactTooltip>
                        <AppCheckBox
                          isChecked={isUseCredits}
                          onClick={() => {
                            onUseCredits(!isUseCredits);
                            if (
                              !isUseCredits &&
                              selectedSupplierForBuyer?.invoices?.creditMemosDetails
                                .totalRemainingCredit
                            ) {
                              if (
                                selectedSupplierForBuyer?.invoices?.creditMemosDetails
                                  .totalRemainingCredit > totalPay
                              ) {
                                onSetCreditToUse(totalPay);
                              } else {
                                onSetCreditToUse(
                                  selectedSupplierForBuyer?.invoices?.creditMemosDetails
                                    .totalRemainingCredit
                                );
                              }
                            } else {
                              onSetCreditToUse(0);
                            }
                          }}
                          checkSize={16}
                          className="h-5 w-5 rounded-md "
                        />
                        <div className={twMerge('ml-2 use-credit-text ')}>
                          Use <span>{`${formatMoney(creditAmount, 2)}`}</span>Credits
                        </div>
                      </div>
                    )}
                  {/* {selectedSupplier &&
                    selectedSupplier.integrationType === 'CODAT_NETSUITE' &&
                    totalPay - creditAmount === 0 && (
                      <p className="text-2xs text-red-600">
                        You can not make a full credit transaction
                      </p>
                    )} */}
                </div>
              )}
              {isSelectPaymentMethod && (
                <div className="w-full px-4">
                  <div className="flex flex-col justify-between  mt-4 rounded-xl PRIMARY_50-BG px-5 py-4">
                    <div>
                      <div className="flex flex-row justify-between  mb-2 ">
                        <div className="amount-label-text">Amount</div>
                        <div className="amount-text">{`${formatMoney(totalPay, 2)}`}</div>
                      </div>
                      {discountPayment > 0 && renderDiscountDropDown()}
                      {isUseCredits && (
                        <div className="flex flex-row justify-between  mb-2 ">
                          <div className="amount-label-text">Credits</div>
                          <div className="amount-text">{`-${formatMoney(
                            totalCreditsToUse,
                            2
                          )}`}</div>
                        </div>
                      )}
                    </div>
                    {fees !== 0 && (
                      <div className="flex flex-row justify-between w-full pb-2">
                        <div className="detailText w-1/5">Fees</div>
                        <div className="amount-text">{`+${formatMoney(fees, 2)}`}</div>
                      </div>
                    )}
                    <div className="flex flex-row justify-between w-full pt-2  border-t">
                      <div className="amount-text">Total Payment</div>
                      <div className="totalText">{`${formatMoney(
                        totalPay - totalCreditsToUse - discountPayment + fees,
                        2
                      )}`}</div>
                    </div>
                  </div>
                  <div className="create-release-info ">
                    <div className="mr-2">
                      <LightbulbFilament color={COLORS.INFO_900} size={24} />
                    </div>
                    <div className="create-release-info-text ">
                      We have a special feature that <span>auto-generates</span> the lien release
                      request for you when you pay through Dill! If you would like to add more
                      templates or supplier contacts, please go to the Lien Releases tab
                    </div>
                  </div>

                  <div className="my-3 flex">
                    <AppCheckBox
                      isChecked={sendRealese}
                      onClick={onAutoGenerateRelease}
                      checkSize={16}
                    />
                    <div className="ml-2 text-base cursor-pointer" onClick={onAutoGenerateRelease}>
                      Auto-generate lien release request
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {isSelectPaymentMethod && sendRealese && (
            <div className="payment-invoice-table-modal-right-content px-3 border-l ">
              <AppCreateRealease
                selectedRelease={selectedRelease}
                paymentAmount={totalPay}
                onCreateRelease={handleCreateRelease}
                groupedInvoiceReleases={groupedInvoiceReleases}
                releaseErrors={releaseErrors}
              />
            </div>
          )}
        </div>
        <div>
          <hr className="h-px my-1 bg-gray-200 border-0 dark:bg-gray-200"></hr>
          <div className="flex items-center justify-center py-1 px-3 w-full">
            {((Object.keys(releaseErrors).length > 0 && sendRealese) ||
              selectedPaymentMethod == '') &&
              isSelectPaymentMethod && (
                <div className="create-release-error">
                  <div className="mr-2">
                    <Warning color={COLORS.ERROR_500} size={24} />
                  </div>
                  <div>
                    {selectedPaymentMethod == '' && (
                      <div className="create-release-error-info">
                        Payment Method is not selected
                      </div>
                    )}
                    {Object.keys(releaseErrors).length > 0 && sendRealese && (
                      <div className="create-release-error-info">
                        Please review for any missing fields in your lien release requests
                      </div>
                    )}
                  </div>
                </div>
              )}
          </div>
          {invoiceListErrorMessage && (
            <div className="px-4 w-[600px]">
              {!isAutoAdjustInvoicesToFitLimit && (
                <p className="text-xs  text-center ERROR_500-CLR px-3">{invoiceListErrorMessage}</p>
              )}
              {isAutoAdjustInvoicesToFitLimit && (
                <div className="WARNING_300-BG rounded-lg flex flex-col justify-center mt-2 py-5 px-3">
                  <div className="flex">
                    <Warning size={28} className="w-2/12 WARNING_900-CLR" />
                    <div className="">
                      <p className="text-sm font-medium WARNING_900-CLR ml-2 ">
                        {invoiceListErrorMessage}
                      </p>
                      <div className="mt-3">
                        <AppButton
                          type="SECONDARY"
                          buttonStyles={{ color: 'white' }}
                          text="Auto Adjust"
                          onClick={() => {
                            setIsAutoAdjustInvoicesToFitLimit(false);
                            onAutoAdjustInvoicesToFitLimit();
                            setInvoiceListErrorMessage('');
                            setReleaseData([]);
                            const otherReasonCheck = partialPaymentReasons.find(
                              (reason) => reason.value.toLowerCase() === 'other'
                            );
                            let reason = '';
                            if (otherReasonCheck) {
                              reason = otherReasonCheck?.value;
                            } else if (partialPaymentReasons.length > 0) {
                              reason = partialPaymentReasons[0]?.value;
                            } else {
                              reason = 'Other';
                            }
                            setPartialPaymentReason(reason);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="flex flex-row-reverse self-end py-3 w-full">
            <AppButton
              text={`${
                totalCreditsToUse === totalPay
                  ? 'Pay Now'
                  : isSelectPaymentMethod
                  ? `Pay ${formatMoney(totalPay - totalCreditsToUse - discountPayment + fees, 2)}`
                  : 'Next'
              }`}
              buttonStyles={{
                marginLeft: '10px',
                marginRight: '10px',
                width: '130px',
              }}
              onClick={handlePayment}
              isDisabled={totalPay === 0}
              // isDisabled={
              // isSelectPaymentMethod && selectedPaymentMethod == ''
              // ||
              // (selectedSupplier &&
              // selectedSupplier.integrationType === 'CODAT_NETSUITE' &&
              // totalPay - creditAmount === 0
              //   ? true
              //   : false)
              // }
            />
            <AppButton
              text={isSelectPaymentMethod ? 'Back' : 'Close'}
              buttonStyles={{
                marginLeft: '10px',
                marginRight: '10px',
              }}
              onClick={() => {
                if (isSelectPaymentMethod) {
                  onToggleIsPaymentMethod(false);
                  setInvoiceListErrorMessage('');
                  setReleaseData([]);
                } else {
                  handleCloseModal();
                }
              }}
              type="TERTIARY"
            />
          </div>
        </div>
        {paymentMethods
          .filter(
            (pymt) =>
              pymt.status === 'MICRO_DEPOSITS_SENT' ||
              pymt.status === 'MICRO_DEPOSITS_VERIFICATION_PENDING' ||
              pymt.status === 'UNVERIFIED'
          )
          .map((paymentMethod) => {
            return (
              <ReactTooltip
                id={`payment-method-tooltip_${paymentMethod.id}`}
                place="top"
                variant="dark"
                opacity={'100%'}
                style={{
                  display: 'flex',
                  background: '#334155',
                  width: '350px',
                  zIndex: '70',
                  borderRadius: '8px',
                  alignItems: 'center',
                  justifyItems: 'center',
                  fontWeight: '600',
                }}>
                {paymentMethod.status === 'MICRO_DEPOSITS_VERIFICATION_PENDING' ? (
                  <span>
                    Two micro-deposits have arrived in your account. Please check for these deposits
                    in your account and verify them in order to use this bank method.
                  </span>
                ) : (
                  <span>
                    Two micro-deposits were sent to this bank account. They should arrive 1-2
                    business days from the day it was added. Once you see these deposits in your
                    account, please verify them in order to use this bank method.
                  </span>
                )}
              </ReactTooltip>
            );
          })}
      </div>
    </div>
  );
};
